@import "../../../data/styles.css";

.card {
	width: 100%;
	mix-blend-mode: normal;
	border-radius: 20px;
	outline: 1px solid var(--border);
}

.card-container {
	padding: 20px;
	padding-bottom: 5px;
}

.card-header {
	width: 100%;
	display: flex;
	padding-top: 10px;
	padding-bottom: 10px;
	align-items: center;
	justify-content: center;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	background-color: var(--secondary-color);
}

.card-icon {
	font-size: 15px;
	color: var(--quaternary-color);
}

.card-title {
	font-family: var(--secondary-font);
	color: var(--quaternary-color);
	font-size: 15px;
	padding-left: 10px;
	letter-spacing: 1.5px;
}

.card-body {
	padding-top: 40px;
}
