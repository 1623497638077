.light {
	/* ------- colors ------- */
	--background-center-content-color: #f1f1f1;
	--background-wrapper-content-color: #e4e4e7;
	--primary-color: #1c1c1c;
	--secondary-color: #1c1c1c;
	--tertiary-color: #27272a;
	--quaternary-color: #e4e4e7;
	--nav-font-color: #1c1c1c;
	/* --nav-font-color: #27272a; */
	/* --link-color: #4682b4; */
	--link-color: #5b8c70;
	--scrollbar-color: #f1f1f1;
	--scrollbar-thumb-color: #f1f1f1;
	--navbar-color: rgb(255, 255, 255, 0.9);

	--border: rgba(0, 0, 0, 0.5);
	--shadow: rgba(0, 0, 0, 0.25);
	/* ---------------------- */

	/* ------- fonts ------- */
	--primary-font: "Heebo", sans-serif;
	--secondary-font: "Roboto", sans-serif;
	/* --------------------- */
}

.dark {
	/* ------- colors ------- */
	--background-center-content-color: #1c1c1c;
	--background-wrapper-content-color: #1c1c1c;
	--primary-color: white;
	--secondary-color: #e4e4e7;
	--tertiary-color: #acacb4;
	--quaternary-color: #27272a;
	--nav-font-color: white;
	/* --nav-font-color: #27272a; */
	/* --link-color: #4682b4; */
	--link-color: #5b8c70;
	--scrollbar-color: #383636;
	--scrollbar-track-color: #565252;
	--navbar-color: rgb(20, 20, 20, 0.9);

	--border: rgba(255, 255, 255, 0.5);
	--shadow: rgba(255, 255, 255, 0.25);
	/* ---------------------- */

	/* ------- fonts ------- */
	--primary-font: "Heebo", sans-serif;
	--secondary-font: "Roboto", sans-serif;
	/* --------------------- */
}
