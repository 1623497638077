@import "../../../data/styles.css";
.options-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;
	width: 100%;
	justify-content: space-between;
}

.nav-container {
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.navbar {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 3vh;
	z-index: 999;
}

.nav-background {
	width: 440px;
	height: 40px;
	padding-left: 0%;
	padding-right: 0%;
	background: var(--navbar-color);
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
	border-radius: 40px;
}

.nav-background::after {
	content: "";
	position: absolute;
	top: -1px; /* Posiciona logo abaixo do nav-background */
	left: 0;
	width: 100%;
	height: 100%; /* Altura suficiente para cobrir o conteúdo abaixo */
	background: rgba(164, 175, 183, 0.5); /* Herda o fundo do nav-background */
	filter: blur(10px); /* Aplica o efeito de desfoque */
	border-radius: 50px;
	z-index: -1; /* Coloca atrás do conteúdo */
}

.nav-list {
	display: flex;
	justify-content: space-between;
	list-style: none;
	align-items: center;
	margin-left: -16px;
	margin-right: 20px;
	margin-top: 11px;
}

.nav-item {
	font-weight: bold;
	font-size: 80%;
}

.nav-item.active a {
	color: var(--link-color) !important;
}

.nav-item a {
	text-decoration: none;
	color: var(--nav-font-color);
}

.nav-item a:hover {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

@media (max-width: 600px) {
	.navbar {
		width: 90%;
		font-size: 50%;
		justify-content: flex-end;
	}

	.nav-background {
		width: 85%;
		height: 40px;
	}

	.nav-background::after {
		width: 85%;
		height: 40px;
		left: 57px;
	}

	.nav-item {
		font-weight: bold;
	}
}
