@import "../../../data/styles.css";

.certificate {
	display: flex;
}

.certificate a {
	text-decoration: none;
}

.certificate-left-side {
	min-width: 20%;
	max-width: 20%;
}

.certificate-logo {
	align-self: flex-start;
	margin-top: 70px;
	margin-left: 20px;
}

.certificate-logo img {
	height: 35px;
	width: 35px;
	border-radius: 10%;
}

.certificate-right-side {
	flex-basis: 80%;
	min-width: 680px;
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	padding: 30px;
}

.certificate-right-side:hover {
	background: var(--quaternary-color);
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.certificate-date {
	padding-top: 30px;
	font-size: 15px;
	color: var(--tertiary-color);
}

.certificate-title {
	color: var(--primary-color);
	font-size: 16px;
	font-weight: 600;
}

.certificate-description {
	padding-top: 10px;
	font-size: 12px;
	color: var(--secondary-color);
	font-weight: 480;
	line-height: 25px;
}

.certificate-link-wrapper {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.certificate-link {
	padding: 10px;
	padding-right: 20px;
	padding-left: 20px;
	border-radius: 10px;
	font-size: 14px;
	color: var(--link-color);
	font-weight: 700;
}

.certificate-link:hover {
	background-color: var(--shadow);
}

@media (max-width: 1024px) {
	.certificate-left-side {
		min-width: 10%;
		max-width: 10%;
	}

	.certificate-right-side {
		flex-basis: 90%;
		min-width: 85%;
		max-width: 85%;
	}
}
