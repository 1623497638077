@import "../../data/styles.css";

.certificates-main-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 160px;
}

.certificates-wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}

.certificates-certificates {
	padding-top: 0px;
	padding-left: 35px;
	padding-bottom: 10px;
	/* border-left: 0.5px solid var(--secondary-color); */
	border-left: 2px solid var(--shadow);
	width: 70%;
}

.colleges-colleges {
	width: 90%;
	align-self: center;
	text-align: center;
	margin-bottom: 70px;
}

.college-container {
	padding-top: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.certificates-container {
	padding-top: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.container-subtitle {
	position: relative;
	width: 100%;
	height: 20px;
	margin-bottom: 40px;
}

.line-subtitle {
	position: absolute;
	width: 100%;
	height: 15px;
	padding-top: 40px;
	margin-bottom: 20px;
	box-shadow: 1px -1px 1px -1px var(--border),
		-1px -1px 1px -1px var(--border);
}

.space-chronological {
	padding-bottom: 40px;
}

.subtitle-text {
	position: absolute;
	bottom: 5px;
	left: 25%;
	padding-left: 20px;
	padding-right: 20px;
	background-color: var(--background-center-content-color);
	font-family: var(--secondary-font);
	letter-spacing: 1px;
	color: var(--primary-color);
	font-size: 30px;
	font-weight: 500;
}

@media (max-width: 1024px) {
	.certificates-title {
		width: 100% !important;
	}

	.show-subtitle {
		width: 100% !important;
	}

	.certificates-certificates {
		width: 90%;
	}

	/* .subtitle-text {
		left: 60% !important;
	} */
}

@media (max-width: 600px) {
	.subtitle-text {
		left: 10% !important;
	}
}
