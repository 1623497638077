@import "../../data/styles.css";

.contact-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	min-height: 100%;
	height: auto;
	margin-top: 160px;
}

.contact-title {
	text-align: center;
	width: 100% !important;
}

.contact-subtitle {
	font-family: "Courier New", Courier, monospace;
	text-align: center;
	font-weight: bold;
	width: 100% !important;
}

.contact-subtitle p {
	margin-top: 35px;
}

.contact-subtitle a {
	color: var(--link-color);
}

.socials-container {
	display: flex;
	flex-direction: column;
	margin-top: 80px;
}

.contact-socials {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
