@import "../../../data/styles.css";

.works-body {
	flex: 1;
	width: 100%;
	align-items: center;
}

.work {
	display: flex;
	font-family: var(--secondary-font);
	margin-bottom: 50px;
	letter-spacing: 0.5px;
	word-spacing: 2px;
	justify-content: center; /* Centraliza horizontalmente */
}

.work-image {
	height: 50px;
	width: 50px;
	border-radius: 10px;
	/*outline: 1px solid white;*/
	margin-bottom: 15px;
	/* box-shadow: 1px 5px 10px var(--shadow); */
}

a {
	text-decoration: none;
	font-family: Arial, sans-serif;
}

.work-title {
	font-size: 15px;
	font-weight: bold;
	/* padding-left: 20px; */
	margin-left: 15px;
	margin-top: 3px;
	color: var(--secondary-color);
}

.work-subtitle {
	display: flex;
	font-weight: lighter;
	font-size: 12px;
	color: var(--tertiary-color);
	word-spacing: normal;
	margin-left: 15px;
	/* 
	padding-left: 60px; */
}

.work-duration {
	font-size: 10px;
	margin-left: 15px;
	color: var(--tertiary-color);
}
